var render = function render(){var _vm=this,_c=_vm._self._c;return _c('yt-live-chat-paid-message-renderer',{staticClass:"style-scope yt-live-chat-item-list-renderer",style:({
    '--yt-live-chat-paid-message-primary-color': _vm.color.contentBg,
    '--yt-live-chat-paid-message-secondary-color': _vm.color.headerBg,
    '--yt-live-chat-paid-message-header-color': _vm.color.header,
    '--yt-live-chat-paid-message-author-name-color': _vm.color.authorName,
    '--yt-live-chat-paid-message-timestamp-color': _vm.color.time,
    '--yt-live-chat-paid-message-color': _vm.color.content,
  }),attrs:{"allow-animations":"","show-only-header":!_vm.content}},[_c('div',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"card"}},[_c('div',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"header"}},[_c('img-shadow',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"author-photo","height":"40","width":"40","imgUrl":_vm.avatarUrl}}),_c('div',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"header-content"}},[_c('div',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"header-content-primary-column"}},[_c('div',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"author-name"}},[_vm._v(" "+_vm._s(_vm.authorName)+" ")]),_c('div',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"purchase-amount"}},[_vm._v(" "+_vm._s(_vm.priceText)+" ")])]),_c('span',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"timestamp"}},[_vm._v(_vm._s(_vm.timeText))])])],1),_c('div',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"content"}},[_c('div',{staticClass:"style-scope yt-live-chat-paid-message-renderer",attrs:{"id":"message","dir":"auto"}},[_vm._v(" "+_vm._s(_vm.content)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }